import { useRouter } from "next/router";
import { useCallback, useContext, useMemo } from "react";
import { loggedUserHost, jobsHostBase, legacyUserHost, oauthFrontHost } from "@constants/environment.constant";
import { TFunction } from "next-i18next";
import { MAGNETO_LOGIN_HEADER_SCHEMA } from "@schemas/layout/header/magnetoUIHeader.schema";
import { AuthContext } from "@context";
import { CandidateContext } from "@context/candidate.context";

type TProps = {
  t: TFunction;
};

const useListMenuProps = ({ t }: TProps) => {
  const router = useRouter();
  const { candidate } = useContext(CandidateContext);
  const { isAuthenticated } = useContext(AuthContext);

  // const currentCandidateLng = candidate?.country?.iso2?.includes("MX") ? "MX" : "CO";
  // const countryPath = `${jobsHostBase}/${currentCandidateLng.toLowerCase()}`;

  const logoutPath = `${oauthFrontHost}/candidates/oauth2/logout`;

  const { urlParam } = useMemo(() => {
    const [pathname] = router.asPath.split("?");
    return { urlParam: pathname, urlBreadcrumText: pathname.replace(/-/gi, " ") };
  }, [router.asPath]);

  const handleLogout = useCallback(() => {
    router.push(logoutPath);
  }, [logoutPath, router]);

  const dinamycUrl = (url: string, text: string) => {
    switch (text) {
      case "userMenu.help":
        return url;
      case "userMenu.allJobs":
        return `${jobsHostBase}${t("routes:jobs")}`;
      case "userMenu.resume":
        return `${loggedUserHost}${url}`;
      default:
        return `${legacyUserHost}${url}`;
    }
  };

  const listMenuUserProps = {
    urlParam,

    menuItems: MAGNETO_LOGIN_HEADER_SCHEMA.menuUser.map((menu) => {
      return {
        ...menu,
        title: t(menu.title),
        items: menu.items.map(({ text, url, icon, slug, ...rest }) => ({
          ...rest,
          icon: icon,
          url: dinamycUrl(url, text),
          text: t(text),
          slug: t(slug)
        }))
      };
    }),

    logout: {
      logoutText: t(MAGNETO_LOGIN_HEADER_SCHEMA.logout),
      onClick: handleLogout
    },

    profileImage: {
      userImage: candidate?.profileImageFileName || null
    },

    isAuthenticated
  };

  return listMenuUserProps;
};

export default useListMenuProps;
