import { TRequestSearch } from "@@types/filter.core";
import { fullBasePath, jobsHostBase, loggedUserHost } from "@constants";
import { MAGNETO_LOGOUT_HEADER_SCHEMA } from "@schemas/layout/header/magnetoUIHeader.schema";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";
import useOpenWidgetCreateAccount from "../useOpenWidgetToApply";

type TProps = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const useMainHeader = ({ t }: TProps) => {
  const { openCreateAccount } = useOpenWidgetCreateAccount();
  const router = useRouter();

  const searchJob = () => {
    const input = document.querySelector("header div form input") as HTMLInputElement;
    const jobBase = jobsHostBase + t<string>("routes:jobs");
    if (input.value) {
      const textValue = input.value.trim().replaceAll(" ", "+");
      const urlFilter = `${jobBase}?q=${textValue}`;
      window.location.replace(urlFilter);
    } else {
      window.location.replace(jobBase);
    }
  };

  const searchBarProps = {
    termValue: router.query?.q || "",
    placeholder: t(MAGNETO_LOGOUT_HEADER_SCHEMA.searchbar.placeholder),
    onSearch: searchJob
  };

  return {
    loginProps: {
      loginText: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signInLink.text),
      loginUrl: `${loggedUserHost}/sign-in`,
      signUpText: t(MAGNETO_LOGOUT_HEADER_SCHEMA.signUpButton.buttonText),
      onClickSignUp: openCreateAccount
    },
    searchBarProps,
    logoProps: {
      url: fullBasePath
    }
  };
};

export default useMainHeader;
