import useDeviceDetected from "@hooks/useDeviceDetected";
import { MagnetoUIProvider } from "magneto365.ui";
import ParameterOriginChecker from "../parameterOriginChecker";
import MagnetoLoginHeaderComponent from "./magnetoLoginHeader.component";

const MagnetoUIHeader = () => {
  const device = useDeviceDetected();

  return (
    <ParameterOriginChecker parameter="app">
      <MagnetoUIProvider device={device}>
        <MagnetoLoginHeaderComponent />
      </MagnetoUIProvider>
    </ParameterOriginChecker>
  );
};

export default MagnetoUIHeader;
