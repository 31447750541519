import { TJobsSchemaTab, TMegaMenuCard } from "@@types/megaMenu";
import { jobsHostBase, jobsSchema } from "@constants";
import { ELandingType } from "@enums/searchHit.enum ";
import { useRedirect } from "@hooks/useRedirect";
import { MegaMenuRepository } from "@repositories";
import { useMegaMenuStore } from "@stores/megaMenu.store";
import { get, isEmpty } from "lodash";

import { TFunction } from "next-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type TProps = {
  t: TFunction;
};

type TPopoverRef = { setShow: (show: boolean) => void };

const useJobsHeader = ({ t }: TProps) => {
  const { redirect } = useRedirect();
  const popoverRef = useRef<TPopoverRef>(null);
  const megaMenuStore = useMegaMenuStore();
  const [selectedSideMenu, setSelectedSideMenu] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isRequested, setIsRequested] = useState(false);

  const selectedKey = useMemo(() => Object.keys(jobsSchema)[selectedTab], [selectedTab]);
  const currentType: ELandingType = useMemo(
    () => jobsSchema[selectedKey].type ?? ELandingType.BY_LOCATION,
    [selectedKey]
  );

  const handleSelectedTab = useCallback((index: number) => {
    setSelectedTab(index);
    setIsRequested(false);
    setSelectedSideMenu(0);
  }, []);

  const onSelectCard = useCallback((index: number) => {
    setSelectedSideMenu(index);
  }, []);

  const handleRedirect = useCallback(
    (url: string, hiddenMenu = true) =>
      () => {
        redirect(url)();
        if (popoverRef.current) {
          popoverRef.current.setShow(!hiddenMenu);
        }
      },
    [redirect]
  );

  useEffect(() => {
    const currentData: TMegaMenuCard[] | undefined = get(megaMenuStore, currentType);
    const getJobs = async () => {
      const landings = await MegaMenuRepository.getJobsByType(currentType);
      megaMenuStore.setMegaMenuStore(currentType, landings);
    };

    if (isEmpty(currentData) && !isRequested) {
      setIsRequested(true);
      getJobs();
    }
  }, [currentType, isRequested, megaMenuStore]);

  return {
    jobAction: { label: t("header:userMenu.jobs") },
    allJobsAction: {
      label: t("header:drawerMenu.allJobs"),
      url: `${jobsHostBase}/empleos`,
      onClick: redirect(`${jobsHostBase}/empleos`)
    },
    popoverRef,
    onChangeTab: handleSelectedTab,
    tabs: Object.keys(jobsSchema).map((key) => {
      const { slug, adapter, type }: TJobsSchemaTab = get(jobsSchema, key);
      const jobs: TMegaMenuCard[] = get(megaMenuStore, type ?? ELandingType.BY_LOCATION);
      return adapter({
        jobs,
        slug,
        t,
        selected: selectedSideMenu,
        onSelectCard,
        key,
        redirectAction: handleRedirect
      });
    })
  };
};

export default useJobsHeader;
