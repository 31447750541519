import { useRouter } from "next/router";
import { useCallback } from "react";
type TUseRedirect = () => {
  redirect: (url: string) => () => void;
};

export const useRedirect: TUseRedirect = () => {
  const router = useRouter();
  const redirect = useCallback(
    (url: string) => () => {
      router.push(url);
    },
    [router]
  );
  return { redirect };
};
