import { TRequestSearch } from "@@types/filter.core";
import withFiltersHoc from "@filters/withFiltersHoc";
import { useMegaMenu } from "@hooks/magnetoUi/megaMenu";
import { MegaMenu } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import React from "react";

type TProps = {
  t: TFunction;
};

type ComposedProps = TProps & {
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const MagnetoHeader: React.FC<ComposedProps> = ({ setFilters, appliedFilters, t }) => {
  const megaMenuProps = useMegaMenu({ t, setFilters, appliedFilters });
  return <MegaMenu {...megaMenuProps} />;
};

export default withTranslation(["header", "routes"])(withFiltersHoc<TProps>(MagnetoHeader));
