import { create } from "zustand";
import lodash from "lodash";
import { TMegaMenuCard, TMegaMenuStore } from "@@types/megaMenu";
import { ELandingType } from "@enums/searchHit.enum ";

const initialState = {
  [ELandingType.BY_LOCATION]: [],
  [ELandingType.BY_SECTOR]: [],
  [ELandingType.BY_OCCUPATION]: [],
  [ELandingType.BY_COMPANY]: [],
  [ELandingType.BY_OCCUPATION_CATEGORY]: [],
  [ELandingType.BY_LOCATION_STATE]: []
};
export const useMegaMenuStore = create<TMegaMenuStore>((set, get) => ({
  ...initialState,
  setMegaMenuStore: (type: ELandingType, value: TMegaMenuCard[]) => {
    const currentValue: TMegaMenuCard[] = lodash.get(get(), type) ?? [];
    if (lodash.isEqual(currentValue, value)) return;
    set(() => ({ [type]: value }));
  }
}));
