import { TFunction } from "next-i18next";
import useBreadcrumb from "./useBreadcrumbs";
import useJobsHeader from "./useJobsHeader";
import useMainHeader from "./useMainHeader";
import useSocialHeader from "./useSocialHeader";
import useListMenuProps from "./useListMenuUserProps";
import { TRequestSearch } from "@@types/filter.core";

type TProps = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const useMegaMenu = ({ t, setFilters, appliedFilters }: TProps) => {
  return {
    breadcrumbsProps: useBreadcrumb(),
    socialHeaderProps: useSocialHeader({ t }),
    mainHeaderProps: useMainHeader({ t, setFilters, appliedFilters }),
    jobsProps: useJobsHeader({ t }),
    listMenuUserProps: useListMenuProps({ t })
  };
};

export default useMegaMenu;
