import { TRequestSearch } from "#types/filter.core";
import { TOffersSuggested } from "#types/jobs";
import { AuthContext, JobsContext, LandingContext } from "@context/index";
import { EDeviceTypes } from "@enums";
import { JobsRepository, JobsWithAuthRepository } from "@repositories";
import { getAllUtms, stateToQueryParams } from "@utils/url";
import React, { useContext, useMemo } from "react";

type TInjectedProps = {
  appliedFilters: TRequestSearch;

  setFilters: (payload: TRequestSearch) => Promise<void>;
};

const withFiltersHoc = <T,>(WrappedComponent: React.FC<T & TInjectedProps>) => {
  const WithFilters: React.FC<T> = (props: T) => {
    const contextValue = useContext(JobsContext);
    const authContext = useContext(AuthContext);
    const landingContext = useContext(LandingContext);
    const { landingPage } = landingContext;
    const { setContextValue } = contextValue;

    const repository = useMemo<typeof JobsRepository>(() => {
      if (authContext.isAuthenticated) return JobsWithAuthRepository;
      return JobsRepository;
    }, [authContext.isAuthenticated]);

    const setFilters = async (payload: TRequestSearch) => {
      setContextValue((current) => ({ ...current, filtersApplied: payload, loading: true }));
      const searchResponse = await repository.search(payload);
      const selectedJob = contextValue.device === EDeviceTypes.desktop ? searchResponse.rows[0] || null : null;
      const similarVacancies =
        (selectedJob && (await repository.getSimilarVacancies(selectedJob.id, landingPage?.companyId))) || [];

      const offersSuggestedResponse: TOffersSuggested[] =
        (selectedJob && (await repository.getOffersSuggested(selectedJob.jobSlug))) || [];
      setContextValue((current) => ({
        ...current,
        searchResponse,
        similarVacancies,
        selectedJob,
        offerSuggested: offersSuggestedResponse,
        loading: false
      }));
      stateToQueryParams({ ...payload, ...getAllUtms() }, true);
    };

    const injectedProps: TInjectedProps = {
      appliedFilters: contextValue.filtersApplied,
      setFilters
    };

    return <WrappedComponent {...props} {...injectedProps} />;
  };
  return WithFilters;
};

export default withFiltersHoc;
