import { fullBasePath, SEO_BLOG } from "@constants";
import { socialHeaderTabs, socialHelpOptions } from "@constants/megaMenu.constant";
import { TFunction } from "next-i18next";

type TProps = {
  t: TFunction;
};

const useSocialHeader = ({ t }: TProps) => {
  const verifyArrays = (data: string, socialUrl: string[]) => {
    const dataArray = t(`${data}`, { returnObjects: true });
    const array = Array.isArray(dataArray)
      ? dataArray.map((tab: string, index: number) => ({
          selected: index == 0,
          label: tab,
          url: socialUrl[index]
        }))
      : [];
    return array;
  };

  const blog = { label: "Blog", url: `${fullBasePath}/${SEO_BLOG}` };
  const helpAction = { label: t("header:socialHeader.help") };
  const helpOptions = verifyArrays("header:socialHeader.helpOptions", socialHelpOptions);
  const tabs = verifyArrays("header:socialHeader.tabs", socialHeaderTabs);

  return {
    helpOptions,
    helpAction,
    blog,
    tabs
  };
};

export default useSocialHeader;
